import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['address', 'city', 'state', 'error']
  static values = { country: String }

  connect() {
    console.log('Connected to places controller')
    this.placeSelected = false

    if (typeof google !== 'undefined') {
      this.initAutocomplete()
    } else {
      console.error('Google Maps JavaScript API is not loaded.')
    }
  }

  initAutocomplete() {
    const restriction = this.countryValue ? [this.countryValue] : ['us']

    this.autocomplete = new google.maps.places.Autocomplete(this.addressTarget, {
      types: ['(cities)'],
      fields: ['address_components'],
      componentRestrictions: { country: restriction },
    })

    this.autocomplete.addListener('place_changed', this.placeChanged.bind(this))
    this.addressTarget.addEventListener('input', this.onInput.bind(this))
    this.addressTarget.addEventListener('blur', this.onBlur.bind(this))
  }

  updateRestrictions({ detail: { content } }) {
    let restriction = content.filter((entry) => entry.length > 0)
    if (restriction.length == 0) {
      restriction = this.countryValue ? [this.countryValue] : ['us']
    }
    this.autocomplete.setComponentRestrictions({ country: restriction })
  }

  placeChanged() {
    this.placeSelected = true
    const place = this.autocomplete.getPlace()
    const addressComponents = place.address_components

    if (addressComponents) {
      const formattedAddress = this.formatAddressComponents(addressComponents)
      this.setAddressComponents(formattedAddress)
      this.triggerAutosave()
    }
  }

  onInput() {
    this.placeSelected = false
    this.clearError()
  }

  onBlur() {
    if (!this.placeSelected && this.addressTarget.value.trim() !== '') {
      this.addressTarget.value = ''
      this.clearAddressComponents()
      this.showError('Please select a valid location from the list.')
      this.triggerAutosave()
    }
  }

  setAddressComponents(address) {
    if (this.hasStateTarget) {
      this.stateTarget.value = address.administrative_area_level_1 || ''
    }
    if (address.locality === 'SF') {
      address.locality = 'San Francisco'
    }
    if (this.hasCityTarget) {
      this.cityTarget.value = address.locality || ''
    }
    console.log('this.addressTarget', this.addressTarget)

    this.addressTarget.value = this.addressTarget.value
        .replace(/,\s[^,]*$/, '') // Removes the last comma and trailing text
        .replace(/\s*D\.C\.\s*/, '') // Removes "D.C." with optional leading/trailing spaces
        .trim(); // Strips any extra spaces from the beginning or end
  }

  clearAddressComponents() {
    if (this.hasStateTarget) {
      this.stateTarget.value = ''
    }
    if (this.hasCityTarget) {
      this.cityTarget.value = ''
    }
  }

  showError(message) {
    if (this.hasErrorTarget) {
      this.errorTarget.textContent = message
      this.errorTarget.classList.remove('d-none')
      this.addressTarget.classList.add('is-invalid')
    }
  }

  clearError() {
    if (this.hasErrorTarget) {
      this.errorTarget.textContent = ''
      this.errorTarget.classList.add('d-none')
      this.addressTarget.classList.remove('is-invalid')
    }
  }

  formatAddressComponents(addressComponents) {
    const data = {}
    addressComponents.forEach((component) => {
      const type = component.types[0]
      data[type] = component.short_name
    })
    return data
  }

  triggerAutosave() {
    // Dispatch an event that the autosave feature can respond to
    const event = new CustomEvent('autosave', {
      bubbles: true,
      detail: {
        address: this.addressTarget.value,
        city: this.cityTarget ? this.cityTarget.value : '',
        state: this.stateTarget ? this.stateTarget.value : '',
      },
    })
    this.element.dispatchEvent(event)
  }
}
