import { Controller } from "@hotwired/stimulus";
import * as Turbo from "@hotwired/turbo";

export default class extends Controller {
  static targets = ["uploadBtn"];
  static values = { imageCounter: Number };

  connect() {
    console.log("Hello, Stimulus!", this.element);

    this.form = document.querySelector("#press_releases_images_form");
    this.uploadField = document.querySelector("#uploadField");

    // Avoid multiple event listeners by ensuring this is only called once
    if (!this.uploadField.dataset.listenerAttached) {
      this.uploadField.addEventListener("change", this.upload.bind(this));
      this.uploadField.dataset.listenerAttached = "true";
    }

    this.handleDisableButton();
  }

  onclick(event) {
    event.preventDefault();

    // Disable the button immediately to prevent duplicate requests
    this.disableButton();
    this.uploadField.click();
  }

  async upload(event) {
    event.preventDefault();

    const formData = new FormData(this.form);

    try {
      const response = await fetch(this.form.action, {
        method: this.form.method,
        body: formData,
        headers: {
          "Accept": "text/vnd.turbo-stream.html"
        }
      });

      const turboStreamResponse = await response.text();
      Turbo.renderStreamMessage(turboStreamResponse);
    } catch (error) {
      console.error("Failed to submit the form:", error);
    } finally {
      // Re-enable the button after the upload completes
      this.handleDisableButton();
    }
  }

  handleDisableButton() {
    const actions = [this.enableButton, this.disableButton];

    const isAllowedToAddImage = this.imageCounterValue >= 7;

    actions[Number(isAllowedToAddImage)].call(this);
  }

  disableButton() {
    if (this.uploadBtnTarget?.disabled === true) return;

    this.uploadBtnTarget.disabled = true;
  }

  enableButton() {
    if (this.uploadBtnTarget?.disabled === false) return;

    this.uploadBtnTarget.disabled = false;
  }
}
